.mob-invoice-detail-info{
  height: calc(100vh - 65px);
}

.mob-invoice-detail-info .header{
  height: 100px;
  display: flex;
}

.mob-invoice-detail-info .status-info{
  background-color: rgba(40, 134, 189, 0.1);
  padding: 20px 15px;
  line-height: 2em;

}

.mob-invoice-detail-info .header-icon{
  display: flex;
  align-items: center;
  width: 15%;
  color: #999999;
  position: absolute;
  height: 100px;
}

.mob-invoice-detail-info .header-icon i{
  text-align: right;
}

.mob-invoice-detail-info .info-detail{
  width: 100%; 
  text-align: center; 
  padding: 15px;
  color: #4F4F4F;
}
.mob-invoice-detail-info .info-detail .info-invoice-no{
  font-weight: lighter;
  color: #185070;
}

.mob-invoice-detail-info .info-detail h2, 
.mob-invoice-detail-info .info-detail h3{
  margin: 5px;
}

.mob-invoice-detail-info .info-detail h3{
  color: #185070;
}

.mob-invoice-detail-info .detail-row{
  display: grid; 
  /* justify-content: space-between;  */
  grid-template-columns: 65% 35%;
}

.mob-invoice-detail-info .detail-row .status{
  word-break: break-all;
  margin-left: 5px; 
  color: #247DAF;
  text-align: right
}

.mob-invoice-detail-info .no-doc{
  text-align: center;
  padding: calc((100vh - 150px) / 2) 0px;
  color: gray;
}

.mob-invoice-detail-info .scan-list{
  padding: 15px;
}

.mob-invoice-detail-info .scan-list h3{
  color: #00529C;
}

.mob-invoice-detail-info .scan-list-row{
  display: grid;
  grid-template-columns: 65% 35%;
  /* justify-content: space-between; */
  padding: 5px 0px;
  /* align-items: center; */
}

.mob-invoice-detail-info .scan-list-row .ui.button{
  background-color: white;
  color: #247DAF;
  border: 1px solid;
  margin: 0px;
  white-space: nowrap;
}

.mob-invoice-detail-info .scan-list-row .scan-list-detail{
  text-align: right;
  color: #247DAF;
  font-weight: bold;
}

.mob-invoice-detail-info-mod.ui.segment{
  height: 80vh;
}