.mob-profile{
  color: #247daf;
  height: 100vh;
}

.mob-profile h2{
  padding: 20px;
  padding-bottom: 0px;
}

.mob-profile .info-segment{
  background-color: rgba(40, 134, 189, 0.05);
}

.mob-profile .row-wrapper{
  display: flex; 
  justify-content: space-between; 
  padding: 0px 20px;
}

.mob-profile .row-wrapper .header{
  font-weight: bold; 
  color: #185070; 
  font-size: 1.17em;
}

.mob-profile .row-wrapper .info{
  word-break: break-all;
}

.mob-profile .btn-wrapper{
  text-align: center;
}