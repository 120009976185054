.mod-document-reason .content h2{
  text-align: center;
}

.mod-document-reason .content .row{
  display: flex;
}

.mod-document-reason .content .row .label{
  align-self: center; 
  width: 20%; 
  text-align: right;
}

.mod-document-reason .content .row .ui.dropdown,
.mod-document-reason .content .row .ui.input{
  width: 100%;
}

.mod-document-reason .content .row .ui.input input{
  border: 0;
  border-bottom: 1px solid lightgrey;
  border-radius: 0px;
}

.mod-document-reason .content .ui.basic.button{
  background-color: white;
  color: #247DAF !important;
  border: 1px solid #247DAF;
}

.mod-document-reason .content .ui.button{
  color: white;
  background-color: #247DAF;
  border: 1px solid #247DAF;
}