.mob-task-detail{
  height: 100%;
}

.mob-task-detail .header{
  display: flex;
  flex-direction: column;
  height: calc(100vh - 65px);
}

.mob-task-detail .header .header-row{
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  padding: 20px 5px;
  align-items: center;
}

.mob-task-detail .header .header-row .task-list-tab{
  color: #00529C;
}

.mob-task-detail .header .header-row .serial{
  flex: 1;
  font-weight: bold;
}

.mob-task-detail .header .header-row .btn-left-label{
  background-color: white;
  border: 1px solid;
}

.mob-task-detail .header .header-row .btn-right-label{
  color: white;
}

.mob-task-detail .detail{
  overflow: auto;
}

.mob-task-detail .detail .detail-list{
  margin: 15px;
  margin-bottom: 60px;
}

.mob-task-detail .doc-detail{
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.mob-task-detail .doc-detail h3{
  color: #185070;
}

.mob-task-detail .doc-detail i.icon{
  color: #247DAF;
}

.mob-task-detail .doc-detail-list{
  line-height: 1.7em;
}

.mob-task-detail .doc-detail-list .row{
  display: flex;
  justify-content: space-between;
}

.mob-task-detail .doc-detail-list .status-label{
  text-align: right;
  color: #F2994A;
  font-weight: bold;
}

.mob-task-detail .footer-btn-group{
  display: flex;
  justify-content: center;
  margin-top: 20px;
  bottom: 60px;
  position: fixed;
  width: calc(100% - 30px);
  background-color: white;
  padding: 10px 0px;
}

.mob-task-detail .footer-btn-group .ui.button{
  white-space: nowrap;
  background-color: #247DAF;
  color: white;
}

.mob-task-detail .footer-btn-group .ui.button.inverted{
  white-space: nowrap;
  color: #247DAF;
  background-color: white;
  border: 1px solid;
}

.mob-task-detail .footer-btn-group .ui.button.icon{
  color: #247DAF;
  background-color: white;
  padding: 0;
}

.mob-task-detail .footer-btn-group .ui.button.icon i{
  margin: 0; 
  color: #247DAF;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

/* input[type="file"] {
  display: none;
} */