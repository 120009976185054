.mob-other-menu{
  color: #247daf;
  height: calc(100vh - 65px);
}

.mob-other-menu h2{
  padding: 20px;
  padding-bottom: 0px;
}

.mob-other-menu .row-wrapper-gray{ 
  display: flex; 
  justify-content: space-between; 
  color: #4F4F4F;
  font-weight: bolder;
  font-size: 1.17em;
  background-color: #E0E0E0;
  padding: 16px 20px;
  border-bottom: 1px solid  #C4C4C4;
}

.mob-other-menu .row-wrapper{
  display: flex; 
  justify-content: space-between; 
  padding: 16px 20px;
  border-bottom: 1px solid #C4C4C4;
  color: #4F4F4F;
}
.mob-other-menu .row-wrapper-first{
  display: flex; 
  justify-content: space-between; 
  padding: 16px 20px;
  border-bottom: 1px solid #C4C4C4;
  border-top: 1px solid #C4C4C4;
  color: #4F4F4F;
 
}
.mob-other-menu .row-wrapper .header,
.mob-other-menu .row-wrapper-first .header{

  color: #4F4F4F; 
  font-size: 1.17em;
}

.mob-other-menu .row-wrapper .info{
  word-break: break-all;
}

.mob-other-menu .btn-wrapper{
  text-align: center;
}