.company{
    display: flex;
    flex-direction: column;
    padding: 20px;
    height: 100%;
    justify-content: center;
    color: rgb(36, 125, 175);
}

.company .item{
    flex-direction: row;
    margin: 10px;
    display: flex;
    align-items: center;
}

.company .content-header{
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 20px;
}

.company .content{
    font-size: 16px;
    font-weight: bold;
}

.company .item div{
    flex: 1;
}

.company .company-icon{
    font-size: 25px;
}

.company hr{
    width: 100%;
    height: 2px;
}